<!-- @format -->

<template>
  <div class="mx-auto w-11/12 lg:container">
    <div
      id="content"
      class="flex w-full flex-col items-start justify-center gap-4 px-2"
    >
      <h1 class="mt-2 text-4xl opacity-75">Entre em contato</h1>
      <h3 class="my-2 text-2xl opacity-75">Nossa localização</h3>
      <div
        class="flex w-full flex-col gap-8 rounded border p-4 md:flex-row lg:gap-8"
      >
        <div
          class="mx-auto flex w-full items-center justify-center border px-4 md:w-1/4 lg:mx-4"
        >
          <img
            src="../assets/nasscarlogo.png"
            alt="NASSCAR"
            title="NASSCAR"
            class="w-full md:w-1/2"
          />
        </div>
        <div class="flex flex-col gap-2 opacity-75">
          <strong class="">NASSCAR</strong>
          <address class="flex flex-col gap-2">
            <p>Nass CR Comércio de Ferramentas LTDA ME</p>
            <p>Rua Dona Francisca 3309 Apto 200</p>
            <p>Bairro Saguaçu</p>
            <p>Joinville-SC-Brasil</p>
            <p>Cep. 89221 - 009</p>
          </address>
        </div>
        <div class="opacity-75">
          <strong>Telefone</strong>
          <p class="">(47) 99713-0100</p>
          <strong>Fax</strong>
          <p class="">(47) 3435-4538</p>
        </div>
      </div>
      <form
        action=""
        method="post"
        enctype="multipart/form-data"
        class="flex w-full flex-col px-8"
      >
        <div class="flex w-full flex-col items-center justify-center gap-8">
          <legend class="w-full rounded border-b-2 px-2 py-1">
            Formulário de contato
          </legend>
          <div
            class="flex w-full flex-col gap-2 self-end lg:w-5/6 lg:flex-row lg:items-center lg:justify-center"
            required
          >
            <label class="px-4 lg:w-1/6 lg:px-0" for="input-name"
              >Seu nome</label
            >
            <input
              type="text"
              name="name"
              value=""
              id="input-name"
              class="w-full rounded-md border p-1"
            />
          </div>
          <div
            class="flex w-full flex-col gap-2 self-end lg:w-5/6 lg:flex-row lg:items-center lg:justify-center"
            required
          >
            <label class="px-4 lg:w-1/6 lg:px-0" for="input-email"
              >Seu e-mail</label
            >
            <input
              type="text"
              name="email"
              value=""
              id="input-email"
              class="w-full rounded-md border p-1"
            />
          </div>
          <div
            class="flex w-full flex-col gap-2 self-end lg:w-5/6 lg:flex-row lg:items-center lg:justify-center"
            required
          >
            <label class="px-4 lg:w-1/6 lg:px-0" for="input-enquiry"
              >Dúvida</label
            >
            <textarea
              name="enquiry"
              rows="10"
              id="input-enquiry"
              class="w-full rounded-md border p-1"
            ></textarea>
          </div>
        </div>
        <input
          class="mx-2 my-4 self-end rounded border bg-menu-color p-3 px-4 text-white shadow"
          type="submit"
          value="Enviar"
        />
      </form>
    </div>
  </div>
</template>
