<template>
  <article class="my-8 w-full">
    <div class="mx-auto flex w-11/12 flex-col items-center gap-4 lg:container">
      <div
        class="grid h-full w-full grid-cols-1 place-content-center place-items-center gap-8 md:grid-cols-2 lg:grid-cols-4"
      >
      Não há produtos neste departamento
      </div>
    </div>
  </article>
</template>
