import { createStore } from "vuex";

export default createStore({
  state: {
    produtos: [
      {
        id: 1,
        titulo: "VAS5054A Interface De Diagnóstico Automotivo - VAS para VW AUDI",
        informacoesTecnicas: ["Cor: Preto"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "Cabo USB", "Adaptador Bluetooth"],
        funcao: ["Fazer diagnóstico  com a transferencia de dados via Bluetooth"],
        peso: ["500g"],
        dimensoes: ["Altura: 20 cm", "Largura: 20 cm", "Comprimento: 30 cm"],
        descricao: "O 5054A VAS é uma interface universal de diagnóstico para os veículos do grupo Volkswagen e todos os sistemas OBD dos veículos de outros fabricantes. A interface Bluetooth integrada liga o veículo com um caderno, PC desktop ou sistema de teste. Sem cabos são necessários para o diagnóstico do veículo utilizando o 5054A VAS. ",
        idiomas: ["Portugues", "Espanhol", "Ingles"],
        extras: ["http://www.youtube.com/watch?v=ZcvtyME-6Js&feature;=related"],
        preco: 1200.0,
        imagens: ["https://web.archive.org/web/20220420060043im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/normais/VAS5054aa-500x500.png"],
        tag: "Scanner",
      },
      {
        id: 2,
        titulo: "RENAULT CLIP - Scanner Diagnóstico v170 clip",
        informacoesTecnicas: ["Modelo: Renault Clip", "Cor: Preto e cinza"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["CAN interface Clip", "Cabo de conexã de 12 pinos para automóveis até 1996 anos", "Cabo de conexã de 16 pinos para automóveis novos", "Cabo USB para ligação ao PC", "1 CD com Renault Clip (pode ser instalado em qualquer PC)"],
        funcao: ["Faz diagnósticos em carros da Renault"],
        peso: ["1KG"],
        dimensoes: ["Altura: 10 cm", "Largura: 15 cm", "Comprimento: 40 cm"],
        descricao: "Renault CAN Clip é uma ferramenta dediagnósticos para carros da Renault, capaiz e diagnosticar acidentes da central pelo computador        Ele contém todas as últimas informações e tecnológia para diagnosticar todos os problemas.",
        idiomas: ["Português"],
        extras: [],
        preco: 1585.0,
        imagens: ["https://web.archive.org/web/20220328110018/http://www.nasscar.com.br/image/cache/catalog/renault-clip-500x500.png"],
        tag: "Scanner",
      },
      {
        id: 3,
        titulo: "BENZ XP STAR C3 - Kit completo com notebook e EOL Liberado + Maleta",
        informacoesTecnicas: ["Marca: Actia", "Modelo: Star C3", "Cor: Preto"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: ["INTERFACE", "Cabo 16+PIN OBD II", "Cabo 38+PIN", "Cabo14+PIN", "Cabo 4+ pin", "Cabo de comunicação seria (RS232), Caixa de armazenagem é opcional.", "Notebook DLL 630 é um computador usado, pois nao existe mais novo para a venda, e precisa ser este modelo."],
        funcao: ["XP-Benz  realiza todas as funções da interface oficial de fábrica BENZ Star2000"],
        peso: ["9kg"],
        dimensoes: ["Altura: 15 cm", "Comprimento: 50 cm", "Largura: 30 cm"],
        descricao: "O Scanner automotivo é uma ferramenta essencial no diagnóstico e reparo do sistema de segurança como o code e de injeção  eletrônica automotiva. Com uma moderna e avançada tecnologia os sistemas de injeção de combustível contam com módulos eletrônicos  que são acessados e entram em comunicação com estes componentes via software específico. ",
        idiomas: ["Português"],
        extras: [],
        preco: 6200.0,
        imagens: ["https://web.archive.org/web/20220412165342/http://www.nasscar.com.br/image/cache/catalog/vendidos/normais/grd_Mercedez-500x500.png"],
        tag: "Scanner",
      },
      {
        id: 4,
        titulo: "Sbb - Silca Black Box - Programador Chaves V33.02",
        informacoesTecnicas: ["Cor: Preto", "Modelo: v.33.02"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "Cabo OBD2", "Fonte", "CD"],
        funcao: ["Programa chaves transponder na central dos veículos."],
        peso: ["1kg"],
        dimensoes: ["Altura: 10 cm", "Largura: 30 cm", "Comprimento: 30 cm"],
        descricao: "SBB é o mais novo e completo equipamento para identificação e programação de chaves transponder na central dos veículos.",
        idiomas: ["Varios idiomas inclusive português"],
        extras: [],
        preco: 985.0,
        imagens: ["https://web.archive.org/web/20220221040805/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/ad-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 5,
        titulo: "Adblue Multi Marcas - Emulador linha pesada",
        informacoesTecnicas: ["Marca: Adblue", "Cor: Preto", "Modelo: V5"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "CD"],
        funcao: ["Dispositivo para emissão de poluentes dos veiculos Diesel"],
        peso: ["200g"],
        dimensoes: ["Altura: 5 cm", "Comprimento: 20 cm", "Largura: 10 cm"],
        descricao: "Este dispositivo funciona sem interferir no trabalhar de outros sistemas eletrônicos do veículo. O emulador AdBlue tem uma vasta gama de modelos de veículos suportados e há apenas uma versão de emulador para todos os caminhões suportados, basta fazer o upload do programa necessário para a Caixa emulador de AdBlue e você pode instalá-lo em qualquer caminhão suportado.",
        idiomas: ["ingles"],
        extras: [],
        preco: 2000.0,
        imagens: ["https://web.archive.org/web/20220328104510im_/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/h-500x500.png"],
        tag: "Acessorio",
      },
      {
        id: 6,
        titulo: "Galletto 1260 Cabo De Programação Via Obdii Obd2 Galeto 1260",
        informacoesTecnicas: ["Cor: Preto", "Modelo: 1260"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Conector SAE J1962 OBD de 16 pinos", "Interface", "CD", "Conector USB"],
        funcao: ["Permite ler e gravar o Dump da memoria flash da centralina dos veículos recentes. Ideal para profissionais que pretendem fazer um serviço de potenciação rápida."],
        peso: ["200g"],
        dimensoes: ["Altura: 5 cm", "Largura: 18 cm", "Comprimento: 25 cm"],
        descricao: "O Galletto programa centrais, sendo facil e rápido de usa apenas necessita um computador com porta USB. Ele lê e grava o Dump da memória flash do modo de injeção dos veículos recentes.",
        idiomas: ["Ingles"],
        extras: [],
        preco: 459.0,
        imagens: ["https://web.archive.org/web/20220125220800im_/http://www.nasscar.com.br/image/cache/catalog/IMAGENS%20PG-500x500.jpg"],
        tag: "Conectores",
      },{
        id: 7,
        titulo: "FORD VCM IDS - Scanner Automotivo para Ford mazda land Rover e jaguar",
        informacoesTecnicas: ["Marca: VCM", "Cor: vermelho e preto"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "CD", "Fonte", "Obd2", "Cabo de Comunicação"],
        funcao: [],
        peso: ["1,3kg"],
        dimensoes: ["Altura: 5 cm", "Largura: 30 cm", "Comprimento: 20 cm"],
        descricao: "O Scanner automotivo é uma ferramenta essencial no diagnóstico e reparo do sistema de segurança como o code e de injeção  eletrônica automotiva. Com uma moderna e avançada tecnologia os sistemas de injeção de combustível contam com módulos eletrônicos  que são acessados e entram em comunicação com estes componentes via software específico",
        idiomas: [],
        extras: [],
        preco: 2100.0,
        imagens: ["https://web.archive.org/web/20220419041159im_/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/pl597945-gna600_vcm_2_in_1_auto_diagnostics_tools_for_honda_ford_mazda_jaguar_and_landrove-500x500.png"],
        tag: "Scanner",
      },
      {
        id: 8,
        titulo: "Scanner Universal Autocom DELPHI CDP Pro - Caminhoes E automóveis - com todos cabos",
        informacoesTecnicas: ["Cor: Preto e cinza", "Marca: Autocom", "Modelo: CDP Pro"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "Cabo OBD2"],
        funcao: ["Faz diagnóstico baseados em um PC com a finalidade de leitura e apagamento de diagnóstico falha de códigos, dados em tempos real e ajustes"],
        peso: ["800g"],
        dimensoes: ["Altura: 10 cm", "Largura: 15 cm", "Comprimento: 25 cm"],
        descricao: "Autocom CDP Pro é a ferramenta de diagnóstico OBD II baseado em um PC com a funcionalidade completa para leitura e apagamento de diagnóstico falha códigos (DTC), dados em tempo real, ativações de componentes, ajustes e assim por diante. CDP Pro suporta 16 tomada de diagnóstico do pólo (J1962) e lida com todos os padrões de comunicação conhecidas para diagnóstico de veículo",
        idiomas: ["Português"],
        extras: [],
        preco: 10.0,
        imagens: ["https://web.archive.org/web/20220328110020im_/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/ip1kdvehltf7vz664pioa__17129_zoom-550x550-500x500.png"],
        tag: "Scanner",
      },
      {
        id: 9,
        titulo: "Scanner GNA600 - Scanner diagnóstico original HONDA",
        informacoesTecnicas: ["Cor: Azul e preto", "Modelo: GNA600"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Unidade GNA600", "4m de cabo PC Ethernet-USB Host", "Cabo curto com 16 pinos DLC", "Cabo longo com 16 pinos DLC", "Adaptador de cabo DLC1 para DLC2", "Adaptador de conector de rede para USB", "Maleta para carregar (Opcional)"],
        funcao: ["O GNA600 pode diagnosticar todos os sistemas dos veículos Honda e Acura nos modelos fabricados desde 1992."],
        peso: ["1KG"],
        dimensoes: ["Altura: 10 cm", "Largura: 25 cm", "Comprimento: 45 cm"],
        descricao: "Scanner Diagnóstico VW - Audi - Skoda O 5054A VAS é uma interface universal de diagnóstico pa..",
        idiomas: ["Vários idiomas, menos português"],
        extras: [],
        preco: 2500.0,
        imagens: ["https://web.archive.org/web/20220412165345im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/normais/gna600-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 10,
        titulo: "Kit cabos mercedes benz star diagnóstico",
        informacoesTecnicas: ["Cor: Preto", "Marca: Mercedes", "Modelo: Benz"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Cabo 16+PIN OBD II", "Cabo 38+PIN", "Cabo14+PIN", "Cabo 4+ pin", "Cabo de comunicação serial (RS232)"],
        funcao: [],
        peso: [],
        dimensoes: ["Altura: 10 cm", "Largura: 20 cm", "Comprimento: 30 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 1100.0,
        imagens: ["https://web.archive.org/web/20220323230206im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/star2-500x500.jpg"],
        tag: "Cabo",
      },
      {
        id: 11,
        titulo: "Notebook Dell Latitude D620",
        informacoesTecnicas: ["Cor: Preto e Cinza", "Marca: Dell", "Modelo: Latitude D620"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Notebook não é novo", "Fonte", "Intel Core2Duo 1,80GHZ, 667Mhz, Cache 2Mb L2.", "Memória 2.0GB, DDR2-667 SDRAM, 2 Slots", "Tela de LCD 14.1 polegadas Wide WXGA", "Placa Gráfica Aceleradora Intel Integrada 256MB", "Disco rígido de 120GB SATA, 5400RPM", "Modem 56Kbps, Rede 10/100, WiFi", "Combo 24 CDRW/DVD.", "Conexões", "01 porta serial, padrão RS-232", "PCMCIA", "01 SVGA DB-15", "04 USB 2.0"],
        funcao: [],
        peso: ["2,4kg"],
        dimensoes: ["Altura: 25 cm", "Largura: 5 cm", "Comprimento: 37 cm"],
        descricao: "",
        idiomas: ["Varios"],
        extras: [],
        preco: 1100.0,
        imagens: ["https://web.archive.org/web/20220125214950im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteII/22583-500x500.jpg"],
        tag: "Computadores",
      },
      {
        id: 12,
        titulo: "Bateria Para Notebook Dell Latitude D620 / D630",
        informacoesTecnicas: ["Cor: Preto", "Marca: Dell"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Bateria Dell"],
        funcao: [],
        peso: [],
        dimensoes: ["Altura: 10 cm", "Largura: 20 cm", "Comprimento: 30 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 250.0,
        imagens: ["https://web.archive.org/web/20220221035933/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/bateria-550x550-500x500.png"],
        tag: "Computadores",
      },
      {
        id: 13,
        titulo: "Ad-200 -Auto Scanner universal",
        informacoesTecnicas: ["Cor: vermelho e preto", "Marca: Quicklynks"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "OBD2", "Cabo USB", "Manual"],
        funcao: ["Faz diagnosticos de precisão em seu veículo", "Indica as definições de DTC em modelos precedentes desiguais da tela", "Dados vivos de varredura", "Conserva dados da exploração"],
        peso: ["1KG"],
        dimensoes: ["Altura: 10 cm", "Largura: 20 cm", "Comprimento: 25 cm"],
        descricao: "AD-200 EOBD OBD 2",
        idiomas: ["Inglês"],
        extras: [],
        preco: 320.0,
        imagens: ["https://web.archive.org/web/20220420060045im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/normais/20140305_093703-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 14,
        titulo: "Scanner Mb Star C4 - Scanner Para Toda Linha Mercedes Benz",
        informacoesTecnicas: ["Cor: Cinza e preto", "Modelo para Mercedes-Benz"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["INTERFACE","Cabo 16+PIN OBD II", "Cabo 38+PIN", "Cabo 14+PIN", "Cabo 4+PIN", "Cabo de comunicação seria (RS232)", "Caixa de armazenamento", "Modo EOL de alteração de portencia e limite de velocidade", "Computador Dell D630 (seminovo, pois precisa ser este modelo de computador)"],
        funcao: ["Faz diagnósticos completos de modelos desede 1995 até os modelos atuais"],
        peso: ["9kg"],
        dimensoes: ["Altura: 20 cm", "Largura: 25 cm", "Comprimento: 35 cm"],
        descricao: "O Scanner automotivo é uma ferramenta essencial no diagnóstico e reparo do sistema de segurança como o code e de injeção eletrônica automotiva. Com uma moderna e avançada tecnologia os sistemas de injeção de combustível contam com módulos eletrônicos que são acessados e entram em comunicação com estes componentes via software específico",
        idiomas: ["Português"],
        extras: [],
        preco: 9650.0,
        imagens: ["https://web.archive.org/web/20220207050742im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/Scanner_c4_1%20(2)-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 15,
        titulo: "Scanner automotivo Delphi",
        informacoesTecnicas: ["Cor: Cinza", "Marca: Delphi", "Modelo: Autocom"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["INTERFACE", "DVD com software", "Kit de 8 cabos para linha automotiva"],
        funcao: ["Faz a leitura", "Apaga os códigos de erros", "leitura em tempo real de dados de sensores e atualizados"],
        peso: ["1 kg"],
        dimensoes: ["Altura: 15 cm", "Largura: 30 cm", "Comprimento: 50 cm"],
        descricao: "Scanner Universal Delphi funciona em uma grande variedade de veículos tanto nacional quanto importados, o mesmo aparelho funciona para linha leve e pesada. Dentre suas funções destacamos leitura e códigos de erros.",
        idiomas: ["Português"],
        extras: [],
        preco: 1200.0,
        imagens: ["https://web.archive.org/web/20220420054929im_/http://www.nasscar.com.br/image/cache/catalog/scanner-automotivo-autocom-cdp-delphi-gratisinstalaco20133-15297-MLB20098684699_052014-O-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 16,
        titulo: "CARPROG V4.95",
        informacoesTecnicas: ["Marca: Codecard.Lt", "Cor: Cinza e preto", "Modelo: Carprog"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["CarProg interface principal", "A1 - Cabo Adaptador OBD2", "D1 - Adaptador Airbag", "A3 - Transponder / Antena", "A10 - Cabo adaptador Motorola", "A11 - Cabo Adaptador BMW EWS", "A16 - Cabo de rádio de carro", "A15 - Cabo Airbag", "A17 - Cabo Honda Airbag OBD2", "A4 - Cabo Adaptador Eeprom", "A5 - Cabo Clipe DIP8", "A6 - Cabo Clipe SOIC8", "A7 - Mercedes Benz Cabo 38 pinos", "A8 - para BMW Cabo de 20 pinos", "RNS510 Cabo Adaptador", "Cabo VOLVO FORD CAN 2006", "Cabo FIAT CAN 2006", "C1 OBD2 Cabo Femea", "Cabo USB", "Cabo de alimentação", "CD (v4, 01, manuel arquivos em pdf)."],
        funcao: ["Faz diagnósticos profissionais para seu carro, programação, airbags e imobilizador do mesmo"],
        peso: ["1,2kg"],
        dimensoes: ["Altura: 10 cm", "Largura: 20 cm", "Comprimento: 25 cm"],
        descricao: "CAR PROG - é uma ferramenta de diagnóstico profissional utilizada para diagnóstico de seu carro programação, airbags e imobilizador do mesmo. Utilização facil - Software compatível com Windows XP, funciona sem qualquer instalação, utiliza a porta USB.",
        idiomas: ["ingles", "portugues", "tchecoslovaquio"],
        extras: [],
        preco: 2500.0,
        imagens: ["https://web.archive.org/web/20220228210906im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/277__40697_zoom__92494_zoom-500x500.png"],
        tag: "Scanner",
      },
      {
        id: 17,
        titulo: "Bsi Renault S118522002c / 8200249128a",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Em otimo estado",
        idiomas: [],
        extras: [],
        preco: 140.0,
        imagens: ["https://web.archive.org/web/20210226192628im_/http://www.nasscar.com.br/image/cache/catalog/kits/bsi%20renault-500x500.jpg"],
        tag: "Peças",
      },{
        id: 18,
        titulo: "Bsi Citroên C3-F01-00",
        informacoesTecnicas: [],
        garantia: ["nenhuma", "nenhuma"],
        itensInclusos: ["prd", "produto"],
        funcao: ["Produto1", "1"],
        peso: ["500g"],
        dimensoes: ["50cm", "10cm", "5cm"],
        descricao: "-Produto usado -Ótimo estado -Código:965799380 -Aplicação:Citroên C3/Peugeot",
        idiomas: [],
        extras: [],
        preco: 400.0,
        imagens: ["https://web.archive.org/web/20180928110631im_/http://www.nasscar.com.br/image/cache/catalog/modulo,chave,transponder%20e%20etc/bsi%20f12-500x500.jpg"],
        tag: "Peças",
      },
      {
        id: 19,
        titulo: "Bsi Citroên/Peugeot 307-BSI 2004 H02",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Ótimo estado -Código:965799380 -Aplicação:Citroên C3/Peugeot",
        idiomas: [],
        extras: [],
        preco: 400.0,
        imagens: ["https://web.archive.org/web/20180928110631im_/http://www.nasscar.com.br/image/cache/catalog/modulo,chave,transponder%20e%20etc/bsi%20f12-500x500.jpg"],
        tag: "Peças",
      },
      {
        id: 20,
        titulo: "Bsi Mégane 2.0",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Em otimo estado",
        idiomas: [],
        extras: [],
        preco: 380.0,
        imagens: ["https://web.archive.org/web/20180928182619im_/http://www.nasscar.com.br/image/cache/catalog/Bsi%20megane%202.0-500x500.jpg"],
        tag: "Peças",
      },
      {
        id: 21,
        titulo: "Bsi Peugeot - 9657999880 - Bsi E03-00 / S118085120f",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Ótimo estado -Aplicações:Peugeot/Citroên",
        idiomas: [],
        extras: [],
        preco: 500.0,
        imagens: ["https://web.archive.org/web/20180928141303im_/http://www.nasscar.com.br/image/cache/catalog/modulo,chave,transponder%20e%20etc/bsie03-00-500x500.jpg"],
        tag: "Peças",
      },
      {
        id: 22,
        titulo: "Bsi Peugeot 206 1.4/Peugeot 307",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Ótimo estado -Aplicação:Peugeot 206 1.4/Peugeot 307 -Código:BSI E02-00/9657999780",
        idiomas: [],
        extras: [],
        preco: 220.0,
        imagens: ["https://web.archive.org/web/20180928113148im_/http://www.nasscar.com.br/image/cache/catalog/modulo,chave,transponder%20e%20etc/bsi%20206,307%201.4-500x500.jpg"],
        tag: "Peças",
      },
      {
        id: 23,
        titulo: "Bsi Peugeot 307-2004 H05",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Ótimo estado -Código:96 649 693 80/2004 H05 -Aplicações:Peugeot 307/Citroên",
        idiomas: [],
        extras: [],
        preco: 380.0,
        imagens: ["https://web.archive.org/web/20210226181136im_/http://www.nasscar.com.br/image/cache/catalog/modulo,chave,transponder%20e%20etc/bsi%20peugeot34-500x500.jpg"],
        tag: "Peças",
      },
      {
        id: 24,
        titulo: "Freelander 1 - Sensor de temperatura da saída do Radiador - MEK105210",
        informacoesTecnicas: [],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: ["150 g"],
        dimensoes: ["Altura: 3 cm", "Comprimento: 8 cm"],
        descricao: "Freelander 1 - Sensor de temperatura da saída do Radiador - MEK105210",
        idiomas: [],
        extras: [],
        preco: 130.0,
        imagens: ["https://web.archive.org/web/20210226185531im_/http://www.nasscar.com.br/image/cache/catalog/sensor%20mek-500x500.JPG"],
        tag: "Peças",
      },
      {
        id: 25,
        titulo: "Kit módulo de injeção Celta/Corsa 1.0+imobilizador+miolo da chave-DYDR 93315496",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Em otimo estado -Codigo:DYDR 93315496",
        idiomas: [],
        extras: [],
        preco: 500.0,
        imagens: ["https://web.archive.org/web/20180928101305im_/http://www.nasscar.com.br/image/cache/catalog/kits/dydf1-500x500.jpg"],
        tag: "Peças",
      },
      {
        id: 26,
        titulo: "Cabo Adaptador Honda 3 Pinos Obd2",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "Ponta conector OBD2 16 pinospara connector 3 pinos honda -Compativél com todos os conectores OBDII",
        idiomas: [],
        extras: [],
        preco: 75.0,
        imagens: ["https://web.archive.org/web/20181023072114im_/http://www.nasscar.com.br/image/cache/catalog/honda3pinos-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 27,
        titulo: "Cabo OBDII Femea 10 pinos para OBDII Macho 5 metros",
        informacoesTecnicas: ["Cor: Preto", "Modelo: Femea/macho"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Cabo femea"],
        funcao: [],
        peso: [],
        dimensoes: ["Altura: 10 cm", "Comprimento: 30 cm", "Largura: 20 cm"],
        descricao: "Conector otimo para você montar sua extensão OBDII em sua oficina, visto que o cabo USB não pode ser colocados extensões.",
        idiomas: [],
        extras: [],
        preco: 150.0,
        imagens: ["https://web.archive.org/web/20181023095747im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/caboo-500x500.png"],
        tag: "Conectores",
      },
      {
        id: 28,
        titulo: "Cabo USB para Serial (conversor RS232)",
        informacoesTecnicas: ["Cor: Azul e prata"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Cabo serial-USB"],
        funcao: ["Pode ser utilizado para diversas coisas como transferencia de dados ou arquivos para qualquer entrada USB/SERIAL"],
        peso: ["50 g"],
        dimensoes: ["Altura: 10 cm", "Largura: 20 cm", "Comprimento: 30 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 14.0,
        imagens: ["https://web.archive.org/web/20181023062013im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/caboUSBparaSERIAL-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 29,
        titulo: "Flat lcd conector Bmw",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 65.0,
        imagens: ["https://web.archive.org/web/20181023081528im_/http://www.nasscar.com.br/image/cache/catalog/flat%20bmw-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 30,
        titulo: "Lampada torpedo 6 leds",
        informacoesTecnicas: ["Cor: Prata e amarelo"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Lâmpada"],
        funcao: ["Simplesmente iluminar seu veículo"],
        peso: ["50 g"],
        dimensoes: ["Altura: 5 cm", "Largura: 5 cm", "Comprimento: 10 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 5.0,
        imagens: ["https://web.archive.org/web/20181023021633im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/lampada-500x500.png"],
        tag: "Conectores",
      },
      {
        id: 31,
        titulo: "Placa Led 24 Leds Smd Super Branca",
        informacoesTecnicas: ["Cor: Branca"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Placa de leds", "Conectores"],
        funcao: ["Iluminar seu veículo no seu interior"],
        peso: ["20 cm"],
        dimensoes: ["Altura: 5 cm", "Largura: 15 cm", "Comprimento: 15 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: "R$ 15,00",
        imagens: ["https://web.archive.org/web/20181023072346im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteII/placa-24-leds-teto-xenon-super-branca-torpedo-pingo-led-t10_MLB-O-3632421390_012013-500x500.png"],
        tag: "Conectores",
      },
      {
        id: 32,
        titulo: "Placa Led 48 Leds Smd Super Branca",
        informacoesTecnicas: ["Cor: Branca"],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Produto usado -Em boas condições -Código:iaw-kpb/16989024",
        idiomas: [],
        extras: [],
        preco: 22.0,
        imagens: ["https://web.archive.org/web/20181023075941im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteII/placa-48-leds-teto-xenon-super-branca-torpedo-flexivel-pingo_MLB-O-3952776183_032013-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 33,
        titulo: "Placa Led 48 Leds Smd Super Branca",
        informacoesTecnicas: ["Cor: Branca"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Placa de leds", "Conectores"],
        funcao: ["Iluminar seu veículo no seu interior"],
        peso: ["20 cm"],
        dimensoes: ["Altura: 5 cm", "Largura: 15 cm", "Comprimento: 15 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 22.0,
        imagens: ["https://web.archive.org/web/20181023075941im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteII/placa-48-leds-teto-xenon-super-branca-torpedo-flexivel-pingo_MLB-O-3952776183_032013-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 34,
        titulo: "POMONA Clip-SOIC 5250 8 pinos",
        informacoesTecnicas: ["Cor: Preto e cinza", "Modelo: Pomona 5250"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Pomona"],
        funcao: ["adaptar para gravador de eprom"],
        peso: ["25 g"],
        dimensoes: ["Altura: 5 cm", "Largura: 10 cm", "Comprimento: 15 cm"],
        descricao: "Adaptador SOIC para gravação on-board (direto na placa)",
        idiomas: [],
        extras: [],
        preco: 95.0,
        imagens: ["https://web.archive.org/web/20181023074014im_/http://www.nasscar.com.br/image/cache/catalog/16__65737_zoom__17003_zoom-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 35,
        titulo: "Ponta conector OBD2 16 pinos com capa plástica",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Compativél com todos os conectores OBDII",
        idiomas: [],
        extras: [],
        preco: 30.0,
        imagens: ["https://web.archive.org/web/20181023043712im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/caixas/ponta%20conector%20obdii-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 36,
        titulo: "cabo original obd2 para FORD VCM 2",
        informacoesTecnicas: ["Marca: VCM", "Cor: preto"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Cabo OBB2"],
        funcao: [],
        peso: ["0,3 kg"],
        dimensoes: ["Altura: 5 cm", "Largura: 30 cm", "Comprimento: 20 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 850.0,
        imagens: ["https://web.archive.org/web/20210226192239im_/http://www.nasscar.com.br/image/cache/catalog/cabo%20ford%20vcm2-500x500.jpg"],
        tag: "Conectores",
      },
      {
        id: 37,
        titulo: "Fonte Dell 630",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 59.0,
        imagens: ["https://web.archive.org/web/20180927235009im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/computadores/fonte%20dell-500x500.jpg"],
        tag: "Computadores",
      },
      {
        id: 38,
        titulo: "Memória ram 2 gb pc2 hynix",
        informacoesTecnicas: ["Cor: Verde e preto", "Marca: Dell"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Memória do notebook"],
        funcao: [],
        peso: ["15 g"],
        dimensoes: ["Altura: 3 cm", "Largura: 20 cm", "Comprimento: 30 cm"],
        descricao: "Memória para o notebook dell D630",
        idiomas: [],
        extras: [],
        preco: 40.0,
        imagens: ["https://web.archive.org/web/20180927232128im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/memoria__29412_zoom-500x500.jpg"],
        tag: "Computadores",
      },
      {
        id: 39,
        titulo: "Notebook Dell Latitude D630",
        informacoesTecnicas: ["Cor: Preto e Cinza", "Marca: Dell", "Modelo: Latitude D630"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Notebook não é novo", "Fonte", "Intel Core2Duo 2,00GHZ, 667Mhz, Cache 2Mb L2.", "Memória 2.0GB, DDR2-667 SDRAM, 2 Slots", "Tela de LCD 14.1 polegadas Wide WXGA", "Placa Gráfica Aceleradora Intel Integrada 256MB", "Disco rígido de 120GB SATA, 5400RPM", "Modem 56Kbps, Rede 10/100, WiFi", "Combo 24 CDRW/DVD.", "Conexões", "01 porta serial, padrão RS-232", "PCMCIA", "01 SVGA DB-15", "04 USB 2.0"],
        funcao: [],
        peso: ["2,4 kg"],
        dimensoes: ["Altura: 25 cm", "Largura: 5 cm", "Comprimento: 37 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 1100.0,
        imagens: ["https://web.archive.org/web/20180927211127im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteII/22583-500x500.jpg"],
        tag: "Computadores",
      },
      {
        id: 40,
        titulo: "Caixa para banda universal superior",
        informacoesTecnicas: [],
        garantia: [],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Ideal para organizar circuito integrado",
        idiomas: [],
        extras: [],
        preco: 45.0,
        imagens: ["https://web.archive.org/web/20181001090033im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/caixas/caixa%20para%20banda-500x500.jpg"],
        tag: "Acessorios",
      },
      {
        id: 41,
        titulo: "Emulador linha pesada Iveco Adblue",
        informacoesTecnicas: ["Marca: Adblue", "Cor: Preto", "Modelo: V5"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "CD"],
        funcao: ["Dispositivo para emissão de poluentes dos veiculos Diesel"],
        peso: ["200 g"],
        dimensoes: ["Altura: 5 cm", "Comprimento: 20 cm", "Largura: 10 cm"],
        descricao: "Este dispositivo funciona sem interferir no trabalhar de outros sistemas eletrônicos do veículo. O emulador AdBlue tem uma vasta gama de modelos de veículos suportados e há apenas uma versão de emulador para todos os caminhões suportados, basta fazer o upload do programa necessário para a Caixa emulador de AdBlue e você pode instalá-lo em qualquer caminhão suportado.",
        idiomas: ["Somente inglês"],
        extras: [],
        preco: 1000.0,
        imagens: ["https://web.archive.org/web/20181001172834im_/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/h-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 43,
        titulo: "Emulador linha pesada Renault Adblue",
        informacoesTecnicas: ["Marca: Adblue", "Cor: Preto", "Modelo: V5"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "CD"],
        funcao: ["Dispositivo para emissão de poluentes dos veiculos Diesel"],
        peso: ["200 g"],
        dimensoes: ["Altura: 5 cm", "Comprimento: 20 cm", "Largura: 10 cm"],
        descricao: "Este dispositivo funciona sem interferir no trabalhar de outros sistemas eletrônicos do veículo. O emulador AdBlue tem uma vasta gama de modelos de veículos suportados e há apenas uma versão de emulador para todos os caminhões suportados, basta fazer o upload do programa necessário para a Caixa emulador de AdBlue e você pode instalá-lo em qualquer caminhão suportado.",
        idiomas: ["Somente inglês"],
        extras: [],
        preco: 1000.0,
        imagens: ["https://web.archive.org/web/20181001172834im_/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/h-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 44,
        titulo: "Emulador linha pesada Scania Adblue",
        informacoesTecnicas: ["Marca: Adblue", "Cor: Preto", "Modelo: V5"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "CD"],
        funcao: ["Dispositivo para emissão de poluentes dos veiculos Diesel"],
        peso: ["200 g"],
        dimensoes: ["Altura: 5 cm", "Comprimento: 20 cm", "Largura: 10 cm"],
        descricao: "Este dispositivo funciona sem interferir no trabalhar de outros sistemas eletrônicos do veículo. O emulador AdBlue tem uma vasta gama de modelos de veículos suportados e há apenas uma versão de emulador para todos os caminhões suportados, basta fazer o upload do programa necessário para a Caixa emulador de AdBlue e você pode instalá-lo em qualquer caminhão suportado.",
        idiomas: ["Somente inglês"],
        extras: [],
        preco: 1000.0,
        imagens: ["https://web.archive.org/web/20181001172834im_/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/h-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 44,
        titulo: "Emulador para caminhão volvo Adblue",
        informacoesTecnicas: ["Marca: Adblue", "Cor: Preto", "Modelo: V5"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "CD"],
        funcao: ["Dispositivo para emissão de poluentes dos veiculos Diesel"],
        peso: ["200 g"],
        dimensoes: ["Altura: 5 cm", "Comprimento: 20 cm", "Largura: 10 cm"],
        descricao: "Este dispositivo funciona sem interferir no trabalhar de outros sistemas eletrônicos do veículo. O emulador AdBlue tem uma vasta gama de modelos de veículos suportados e há apenas uma versão de emulador para todos os caminhões suportados, basta fazer o upload do programa necessário para a Caixa emulador de AdBlue e você pode instalá-lo em qualquer caminhão suportado.",
        idiomas: ["Somente inglês"],
        extras: [],
        preco: 1000.0,
        imagens: ["https://web.archive.org/web/20181001172834im_/http://www.nasscar.com.br/image/cache/catalog/Produtos/Entrada/h-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 45,
        titulo: "FLAT LCD Conector para MB W210/W202/W208 DIREITA",
        informacoesTecnicas: ["Cor: Preto e branco"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: ["Flat"],
        funcao: [],
        peso: ["10 g"],
        dimensoes: ["Altura: 1 cm", "Largura: 20 cm", "Comprimento: 28 cm"],
        descricao: "FLAT LCD Conector para MB W210/W202/W208 DIREITA Cabo flat é um cabo de dados que liga dados de HD CD-Rom e disquete",
        idiomas: [],
        extras: [],
        preco: 78.0,
        imagens: ["https://web.archive.org/web/20181001053410im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/Flat_LCD_Connector_for_MB_W210_W202_W208_RIGHT_Lcd_of_Dashboard_5pcs_per_Lot-500x500.jpg"],
        tag: "Acessorios",
      },
      {
        id: 46,
        titulo: "FLAT LCD Conector para Painel VW Fox",
        informacoesTecnicas: ["Cor: Preto e branco"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: ["Flat"],
        funcao: [],
        peso: ["10 g"],
        dimensoes: ["Altura: 1 cm", "Largura: 20 cm", "Comprimento: 28 cm"],
        descricao: "FLAT LCD Conector para MB W210/W202/W208 DIREITA Cabo flat é um cabo de dados que liga dados de HD CD-Rom e disquete",
        idiomas: [],
        extras: [],
        preco: 78.0,
        imagens: ["https://web.archive.org/web/20181001180239im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/pl271758-flat_lcd_connector_for_vw_fox_dashboard-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 47,
        titulo: "Garra Pomona",
        informacoesTecnicas: [],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "Função: -Permite a gravação do componente sem retira-lo da placa. Exemplo de componentes suportados 24c** , 93c** etc. Reduz o risco de danos a placa em virtude de solda. Serve para SOIC 8 150mil e SOIC8 209mil. Obs.: Em alguma placas, devido a presença do componentes periféricos, que atenuam o sinal do programador (gravador), pode ser necessário a retirada do componente (memória) para gravação. Requer programador de EPROM.",
        idiomas: [],
        extras: [],
        preco: 129.0,
        imagens: ["https://web.archive.org/web/20181001154654im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/adaptadores/garra%20pomona-500x500.jpg"],
        tag: "Acessorios",
      },
      {
        id: 48,
        titulo: "Maxitrip - computador de bordo",
        informacoesTecnicas: ["Cor: Preto", "Marca: Aupel", "Modelo: MaxiTrip"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "Cabo USB"],
        funcao: ["O MaxiTrip é um computador automotivo compacto concebido para fornecer informações sobre o seu veículo em tempo real. Ele exibirá informações úteis, tais como:", "média viagem MPG", "velocidade média por viagem", "velocidade média por tanque de gasolina", "Quanto para esvaziar o tanque", "Milhas conduzido em tanques", "Velocidade máxima", "Condução Distância", "Distância para tanque vazio", "Velocidade média", "Combustível utilizado", "Hora para tanque vazio", "O tempo de condução", "Viagem com economia de combustível", "Combustível para tanque vazio"],
        peso: ["500 g"],
        dimensoes: ["Altura: 10 cm", "Largura: 15 cm", "Comprimento: 20 cm"],
        descricao: "Maxitrip Computador de bordo",
        idiomas: ["Inglês"],
        extras: [],
        preco: 650.0,
        imagens: ["https://web.archive.org/web/20181001103151im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteII/raramenteNCPL24-10-8/20140313_091529-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 49,
        titulo: "MaxVideo NC201 - Câmera Digital Automotiva",
        informacoesTecnicas: ["Cor: Vermelho e preto", "Marca: Autel", "Modelo: MV201"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface", "Cabos", "Fontes", "Manual", "Cabo/Câmera"],
        funcao: ["Com sua câmera de cabo flexivel e iluminado amplia, salva e captura imagens.", "Tenha uma ferramenta em mãos que amplia os pequenos e minúsculos detalhes de objetos para sua tela de 3,5"],
        peso: ["1,9 kg"],
        dimensoes: ["Altura: 10 cm", "Largura: 30 cm", "Comprimento: 40 cm"],
        descricao: "Camera Digital Automotiva",
        idiomas: ["Inglês", "Espanhol", "Entre outras"],
        extras: [],
        preco: 2050.0,
        imagens: ["https://web.archive.org/web/20181001025444im_/http://www.nasscar.com.br/image/cache/catalog/maxvideo-nc201-camera-digital-automotiva_MLB-O-3056352890_082012-500x500.jpg"],
        tag: "Acessorios",
      },
      {
        id: 50,
        titulo: "Pinça Wilcos para trabalho com circuito integrado",
        informacoesTecnicas: ["Cor: Prata"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Pinça"],
        funcao: [""],
        peso: [""],
        dimensoes: ["Altura: 5 cm", "Largura: 20 cm", "Comprimento: 20 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 35.0,
        imagens: ["https://web.archive.org/web/20181001170240im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteIII/7077_1_g-500x500.jpg"],
        tag: "Acessorios",
      },
      {
        id: 51,
        titulo: "SONDA EXPLORADORA Nº 47",
        informacoesTecnicas: ["Cor: Prata"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Sonda"],
        funcao: [],
        peso: ["20 g"],
        dimensoes: ["Altura: 3 cm", "Largura: 20 cm", "Comprimento: 20 cm"],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 35.0,
        imagens: ["https://web.archive.org/web/20181001115953im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteIII/GRD_1065_Prod_Sonda_Exploradora_47-500x500.png"],
        tag: "Acessorios",
      },
      {
        id: 52,
        titulo: "Actron Cp9125 Pocket Scanner",
        informacoesTecnicas: ["Cor: Laranja", "Marca: Actron", "Modelo: CP9125"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Interface junto com o cabo OBDII"],
        funcao: ["Faze scanner completos e profissionais", "Analisa os defeitos de seu veículo", "Seja no sistema OBD-II ou CAN ou ABS para todas as marcas"],
        peso: ["400 g"],
        dimensoes: ["Altura: 10 cm", "Largura: 20 cm", "Comprimento: 30 cm"],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 390.0,
        imagens: ["https://web.archive.org/web/20190210005127im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramenteIII/31678152-2-440-overview-1-500x500.gif"],
        tag: "Scanner",
      },
      {
        id: 53,
        titulo: "Auto scanner OBD2",
        informacoesTecnicas: ["Cor: Amarelo", "Modelo: OBD2"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["ferramenta de verificação, manual do usuário, cabo obd2, cabo serial, caso nylon de transporte, CD com biblioteca dtc"],
        funcao: ["Suporta fluxo de dados ou dados do motor vivos (300 itens); ","Exibe informações de status dos sensores do veículo; ","Lê os códigos genéricos (P0, P2, P3, U0) e códigos específicos do fabricante (P1, P3, e U1);","Apaga códigos de problemas e redefine verificar luzes do motor;","Apresenta o estado de prontidão de I / M;","Detecta OBD2 informações quadro congelado.;","Determina a lâmpada indicadora de mau funcionamento status (MIL);","Identifica e exibe Códigos Pendentes;","Recupera VIN (Vehicle Identification No.) em 2002 e mais novos veículos que suportam a modalidade"],
        peso: ["700 g"],
        dimensoes: ["Altura: 5 cm", "Largura: 15 cm", "Comprimento: 20 cm"],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 450.0,
        imagens: ["https://web.archive.org/web/20190210005409im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/scanner/obd342-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 54,
        titulo: "Atualização - Launch Diagun III X431 sem cartão - só arquivos",
        informacoesTecnicas: [],
        garantia: ["3 meses contra defeito de fábrica", "Uma única atualização"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "-Este Diagun possui a mesma quantidade de montadoras do que o diagun normal:Ámerica,Ásia e Europa -Enviamos por e-mail o link para baixar os arquivos",
        idiomas: ["Português"],
        extras: [],
        preco: 440.0,
        imagens: ["https://web.archive.org/web/20190210005308im_/http://www.nasscar.com.br/image/cache/catalog/atualizacao%20diagun-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 55,
        titulo: "Atualização - Launch MASTER X431 com cartão cartão - só trocar o cartão no aparelho",
        informacoesTecnicas: [],
        garantia: ["3 meses contra defeito de fábrica", "Somente uma atualização"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [],
        extras: [],
        preco: 835.0,
        imagens: ["https://web.archive.org/web/20190210005322im_/http://www.nasscar.com.br/image/cache/catalog/atualizacao%20launch-500x500.JPG"],
        tag: "Scanner",
      },
      {
        id: 56,
        titulo: "Atualização - Launch MASTER X431 sem cartão - só arquivos",
        informacoesTecnicas: [],
        garantia: ["3 meses contra defeito de fábrica", "Somente uma atualização"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 35.0,
        imagens: ["https://web.archive.org/web/20190210005339im_/http://www.nasscar.com.br/image/cache/catalog/atualizacao%20launch-500x500.JPG"],
        tag: "Scanner",
      },
      {
        id: 57,
        titulo: "Atualização - Launch Diagun III X431 com cartão",
        informacoesTecnicas: ["Cor: Prata"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Sonda"],
        funcao: [],
        peso: ["20 g"],
        dimensoes: ["Altura: 3 cm", "Largura: 20 cm", "Comprimento: 20 cm"],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 485.0,
        imagens: ["https://web.archive.org/web/20190210005244im_/http://www.nasscar.com.br/image/cache/catalog/atualizacao%20diagun-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 58,
        titulo: "Arquivo individual de decode / reset / casamento",
        informacoesTecnicas: ["Cor: Prata"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Sonda"],
        funcao: [],
        peso: ["20 g"],
        dimensoes: ["Altura: 3 cm", "Largura: 20 cm", "Comprimento: 20 cm"],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 150.0,
        imagens: ["https://web.archive.org/web/20190210005228im_/http://www.nasscar.com.br/image/cache/catalog/immo%20decode-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 59,
        titulo: "All Scanner ITS3 (Toyota TIS Techstream)",
        informacoesTecnicas: ["Cor: Prata"],
        garantia: ["3 meses contra defeito de fábrica", "1 ano de manutenção técnica (cliente responsável apenas pela peça trocadas após os três primeiros meses)"],
        itensInclusos: ["Sonda"],
        funcao: [],
        peso: ["20 g"],
        dimensoes: ["Altura: 3 cm", "Largura: 20 cm", "Comprimento: 20 cm"],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 8500.0,
        imagens: ["https://web.archive.org/web/20190210005206im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/scanner/all%20scanner-500x500.jpg"],
        tag: "Scanner",
      },
      {
        id: 60,
        titulo: "Chave Citroen",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 50.0,
        imagens: ["https://web.archive.org/web/20190210003145im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/capa%20lamina%20citoren-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 61,
        titulo: "Antena de som HB20+transponder da chave",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 139.0,
        imagens: ["https://web.archive.org/web/20190210003222im_/http://www.nasscar.com.br/image/cache/catalog/modulo,chave,transponder%20e%20etc/antena%20de%20som%20hb20-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 62,
        titulo: "BMW HU92 v.2 2 leitor de segredo de chave mecânica",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 200.0,
        imagens: ["https://web.archive.org/web/20190210003240im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/raramente/HU92__92527_zoom__61002_zoom-500x500.png"],
        tag: "Chaves",
      },
      {
        id: 63,
        titulo: "Capa chave Discovery 4",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 220.0,
        imagens: ["https://web.archive.org/web/20190210003258im_/http://www.nasscar.com.br/image/cache/catalog/chave_discovery__74429_zoom-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 64,
        titulo: "Capa da chave Bmw serie F",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 220.0,
        imagens: ["https://web.archive.org/web/20190210003322im_/http://www.nasscar.com.br/image/cache/catalog/chave%20bmw%20serie%20F-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 65,
        titulo: "Capa da chave Bmw-x1-x6-z4",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 175.0,
        imagens: ["https://web.archive.org/web/20190210003342im_/http://www.nasscar.com.br/image/cache/catalog/capas%20de%20chave/capa%20da%20chave%20bmw%20x1-x6-z4-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 66,
        titulo: "Capa da chave canivete C4 Peugeot 307",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 79.0,
        imagens: ["https://web.archive.org/web/20190210003400im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/chave%20c4%20peugeot%20307-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 67,
        titulo: "Capa da chave canivete Hyundai Tucson",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 75.0,
        imagens: ["https://web.archive.org/web/20190210003410im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/capa%20da%20chave%20hyundai%20tucson-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 68,
        titulo: "Capa da chave canivete Land Rover Freelander 3",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 175.0,
        imagens: ["https://web.archive.org/web/20190210003426im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/chave%20canivete%20land%20rover%20discovery%203%20Freelander-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 69,
        titulo: "Capa da chave canivete Land Rover Range Rover",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 175.0,
        imagens: ["https://web.archive.org/web/20190210003442im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/capa%20da%20chave%20canivete%20land%20rover%20range%20rover-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 70,
        titulo: "Capa da chave Chevrolet Corsa e Celta sem telecomando",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 19.0,
        imagens: ["https://web.archive.org/web/20190210003502im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/chave%20chevrolet%20simples-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 71,
        titulo: "Capa da chave Citroen",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 119.0,
        imagens: ["https://web.archive.org/web/20190210003518im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/capa%20da%20chave%20citroen-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 72,
        titulo: "Capa da chave Ford Fusion",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 49.0,
        imagens: ["https://web.archive.org/web/20190210003541im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/capa%20da%20chave%20Ford-500x500.jpg"],
        tag: "Chaves",
      },
      {
        id: 73,
        titulo: "Capa da chave freelander 1",
        informacoesTecnicas: ["Cor: Preta"],
        garantia: ["3 meses contra defeito de fábrica"],
        itensInclusos: [],
        funcao: [],
        peso: [],
        dimensoes: [],
        descricao: "",
        idiomas: [""],
        extras: [],
        preco: 75.0,
        imagens: ["https://web.archive.org/web/20190210003600im_/http://www.nasscar.com.br/image/cache/catalog/vendidos/chaves/capa%20da%20chave%20freelander%201-500x500.jpg"],
        tag: "Chaves",
      },
    ],
    resultadoSearch: [],
    resultadoRotulo: []
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
