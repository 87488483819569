import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import chaveView from '../views/chaveView'
import scannerView from '../views/scannerView'
import imobilizadorView from '../views/imobilizadorView'
import acessoriosView from '../views/acessoriosView'
import computadoresView from '../views/computadoresView'
import conectoresView from '../views/conectoresView'
import pecasView from '../views/pecasView'
import bateriasView from '../views/bateriasView'
import produtosView from '../views/produtosView'
import sobreView from '../views/sobreView'
import politicasView from '../views/politicasView'
import termosView from '../views/termosView'
import contatoView from '../views/contatoView'
import mapaView from '../views/mapaView'
import resultadosView from '../views/resultadosView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/chaves',
    name: 'chaves',
    component: chaveView
  },
  {
    path: '/scanners',
    name: 'scanners',
    component: scannerView
  },
  {
    path: '/imobilizador',
    name: 'imobilizador',
    component: imobilizadorView
  },  
  {
    path: '/acessorios',
    name: 'acessorios',
    component: acessoriosView
  },
  {
    path: '/computadores',
    name: 'computadores',
    component: computadoresView
  },
  {
    path: '/conectores',
    name: 'conectores',
    component: conectoresView
  },
  {
    path: '/peças',
    name: 'peças',
    component: pecasView
  },
  {
    path: '/baterias',
    name: 'baterias',
    component: bateriasView
  },
  {
    path: '/produtos/:id',
    name: 'produtos',
    component: produtosView
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: sobreView
  },
  {
    path: '/politicas',
    name: 'politicas',
    component: politicasView
  },
  {
    path: '/termos',
    name: 'termos',
    component: termosView
  },
  {
    path: '/contato',
    name: 'contato',
    component: contatoView
  },
  {
    path: '/mapa',
    name: 'mapa',
    component: mapaView
  },
  {
    path: '/resultados',
    name: 'resultados',
    component: resultadosView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
