<!-- @format -->

<template>
  
  <main class="flex h-full flex-col items-center justify-between">
    <header
      class="my-4 flex w-full flex-col items-center justify-center gap-8 sm:gap-0"
    >
      <div
        class="flex w-11/12 flex-col items-center justify-evenly gap-4 xl:container sm:flex-row md:px-6"
      >
        <router-link to="/">
          <img src="./assets/nasscarlogo.png" id="logo" />
        </router-link>

        <div
          class="m-auto flex w-11/12 justify-center sm:m-0 sm:mb-4 sm:self-end md:justify-end"
        >
          <input
            type="text"
            class="inline-block w-8/12 rounded-bl rounded-tl border border-gray-400 pl-2 text-sm"
            placeholder="Busca"
            id="pesquisarCaixa"
          />

          <router-link to="/resultados">
            <button
              id="pesquisarBtt"
              class="h-auto w-4/12 rounded-br rounded-tr border border-l-0 border-gray-400 bg-btn-color px-6 py-2 shadow md:w-2/12 md:px-6 lg:w-1/12"
              @click="searchProducts()"
            >
              <svg
                class="m-auto inline-block opacity-75"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </button>
          </router-link>
        </div>
      </div>

      <div
        class="w-11/12 rounded border-b-4 border-b-blue-700 bg-nav-header px-6 text-white xl:container md:rounded-none"
      >
        <div class="flex items-center justify-between rounded-t p-4 md:hidden">
          <span class="font-serif text-lg font-bold">Departamentos</span>
          <button class="rounded bg-menu-color p-1 px-3" @click="handleClick">
            <img
              class="w-5"
              src="./assets/Utility-icons/menu-svgrepo-com.svg"
              alt="Hamburguer Menu"
            />
          </button>
        </div>
        <nav
          class="flex flex-col items-center justify-center gap-4 overflow-hidden border-t border-t-navbar-line-color bg-nav-header py-4 font-arvo text-lg md:flex-row md:flex-wrap md:justify-evenly md:rounded-none md:px-4"
          :class="
            windowWidth < 768
              ? active
                ? 'h-auto'
                : 'm-0 h-0 gap-0 border-t-0 !py-0'
              : 'h-auto'
          "
        >
          <router-link to="/chaves" @click="rotular('Chaves')">CHAVES</router-link>
          <router-link to="/scanners" @click="rotular('Scanner')">SCANNERS</router-link>
          <router-link to="/imobilizador" @click="rotular('Imobilizador')">IMOBILIZADORES</router-link>
          <router-link to="/acessorios" @click="rotular('Acessorio')">ACESSÓRIOS</router-link>
          <router-link to="/computadores" @click="rotular('Computadores')">COMPUTADORES</router-link>
          <router-link to="/conectores" @click="rotular('Conectores')">CONECTORES</router-link>
          <router-link to="/peças" @click="rotular('Peças')">PEÇAS DE VEICULOS</router-link>
          <router-link to="/baterias" @click="rotular('Baterias')">BATERIA MOURA</router-link>
        </nav>
      </div>
    </header>

    <router-view />

    <footer class="w-full bg-footer-color py-6">
      <div
        class="mx-auto flex w-5/6 flex-col items-center gap-4 px-4 text-white"
      >
        <div class="flex w-full justify-evenly">
          <div class="flex h-full justify-self-start">
            <ul class="h-full font-extralight">
              <li class="font-semibold">Informações</li>
              <li><router-link to="/sobre">Sobre</router-link></li>
              <li>
                <router-link to="/politicas"
                  >Política de Privacidade</router-link
                >
              </li>
              <li>
                <router-link to="/termos">Termos e Condições</router-link>
              </li>
            </ul>
          </div>
          <div class="flex h-full justify-self-end">
            <ul class="h-full font-extralight">
              <li class="font-semibold">Serviços ao Cliente</li>
              <li><router-link to="/contato">Entre em Contato</router-link></li>
              <li><router-link to="/mapa">Mapa do Site</router-link></li>
            </ul>
          </div>
        </div>
        <span
          class="h-0.5 w-full rounded bg-navbar-line-color opacity-60"
        ></span>
        <div class="w-full">
          <p>NASSCAR &copy;</p>
        </div>
      </div>
    </footer>
  </main>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    handleClick() {
      this.active = !this.active;
    },
    searchProducts() {

      this.$store.state.resultadoSearch = []

      let barra = document.getElementById("pesquisarCaixa");

      for (let i = 0; i < this.$store.state.produtos.length; i++) {

        console.log(i);

        if (this.$store.state.produtos[i].titulo.toLowerCase().includes(barra.value.toLowerCase())) {

          this.$store.state.resultadoSearch.push(this.$store.state.produtos[i]);

        }

      }

    },
    
    rotular(tag){

      this.$store.state.resultadoRotulo = []

      for (let i = 0; i < this.$store.state.produtos.length; i++) {

        if (this.$store.state.produtos[i].tag.includes(tag)) {
       
          this.$store.state.resultadoRotulo.push(this.$store.state.produtos[i]);
       
        }

      }

    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style></style>
