<template>
  <article class="my-8 w-full">
    
    <div class="mx-auto flex w-11/12 flex-col items-center gap-4 lg:container">
      <div
        class="grid h-full w-full grid-cols-1 place-content-center place-items-center gap-8 md:grid-cols-2 lg:grid-cols-4"
      >
      <article
          v-for="produtos in this.$store.state.resultadoRotulo"
          :key="produtos.id"
          class="col-span-1 row-span-1 flex h-full items-center justify-center text-center"
        >
        <router-link
            :to="'/produtos/' + produtos.id"
            class="border-btn-border-line flex h-full w-11/12 flex-col items-center justify-between border"
          >
            <img class="w-full" :src="produtos.imagens" alt="img" />

            <div class="my-4 mb-8 flex w-full flex-col gap-4 px-4">
              <p
                class="text-title-color w-full font-sans text-lg font-medium leading-tight"
              >
                {{ produtos.titulo }}
              </p>
              <p class="text-base leading-tight opacity-70">
                {{ produtos.descricao }}
              </p>
              <p class="text-price-color text-lg font-bold">
                R$ {{ produtos.preco }},00
              </p>
            </div>

            <div class="flex h-auto w-full items-center justify-evenly">
              <button
                class="bg-card-btn border-btn-border-line flex w-1/3 items-center justify-center border-t py-4"
              >
                <img
                  class="w-4"
                  src="../assets/Utility-icons/cart-large-minimalistic-svgrepo-com.svg"
                  alt="card-icon"
                />
              </button>
              <button
                class="bg-card-btn border-btn-border-line flex w-1/3 items-center justify-center border-x border-t py-4"
              >
                <img
                  class="w-4"
                  src="../assets/Utility-icons/heart-svgrepo-com.svg"
                  alt="card-icon"
                />
              </button>
              <button
                class="bg-card-btn border-btn-border-line flex w-1/3 items-center justify-center border-t py-4"
              >
                <img
                  class="w-4"
                  src="../assets/Utility-icons/arrows-horizontal-svgrepo-com.svg"
                  alt="card-icon"
                />
              </button>
            </div>
          </router-link>
        </article>
      </div>
    </div>
  </article>
</template>
