<template>
  <article
    class="flex w-full items-center justify-center"
    v-for="pdto in this.$store.state.produtos.slice((this.$route.params.id - 1), this.$route.params.id)"
    :key="pdto.id"
  >
    <section
      class="flex w-11/12 flex-col gap-8 xl:container lg:flex-row"
      v-if="pdto.id == this.$route.params.id"
    >
      <div class="my-4 flex w-full flex-col gap-4 lg:w-2/3">
        <div class="w-full mx-10">
          <img :src="pdto.imagens" />
        </div>

        <div class="flex w-full flex-col justify-evenly gap-4">
          <div class="flex w-full gap-0">
            <p class="w-fit rounded border border-b-0 p-3 px-4">Descrição</p>
            <span class="w-full border-b"></span>
          </div>

          <p class="text-base">{{ pdto.descricao }}</p>

          <span class="w-full border-b"></span>

          <div class="flex w-full flex-col gap-2 opacity-70">
            <h1 class="text-base">Informações Técnicas:</h1>
            <ul class="ml-8 list-disc rounded">
              <li v-for="pdtoInfo in pdto.informacoesTecnicas" :key="pdtoInfo">
                {{ pdtoInfo }}
              </li>
            </ul>
          </div>

          <span class="w-full border-b"></span>

          <div class="flex w-full flex-col gap-2 opacity-70">
            <h1 class="text-base">Garatia:</h1>
            <ul class="ml-8 list-disc rounded">
              <li v-for="pdtoInfo in pdto.garantia" :key="pdtoInfo">
                {{ pdtoInfo }}
              </li>
            </ul>
          </div>

          <span class="w-full border-b"></span>

          <div class="flex w-full flex-col gap-2 opacity-70">
            <h1 class="text-base">Itens Inclusos:</h1>
            <ul class="ml-8 list-disc rounded">
              <li v-for="pdtoInfo in pdto.itensInclusos" :key="pdtoInfo">
                {{ pdtoInfo }}
              </li>
            </ul>
          </div>

          <span class="w-full border-b"></span>

          <div class="flex w-full flex-col gap-2 opacity-70">
            <h1 class="text-base">Função:</h1>
            <ul class="ml-8 list-disc rounded">
              <li v-for="pdtoInfo in pdto.funcao" :key="pdtoInfo">
                {{ pdtoInfo }}
              </li>
            </ul>
          </div>

          <span class="w-full border-b"></span>

          <div class="flex w-full flex-col gap-2 opacity-70">
            <h1 class="text-base">Peso:</h1>
            <ul class="ml-8 list-disc rounded">
              <li v-for="pdtoInfo in pdto.peso" :key="pdtoInfo">
                {{ pdtoInfo }}
              </li>
            </ul>
          </div>

          <span class="w-full border-b"></span>

          <div class="flex w-full flex-col gap-2 opacity-70">
            <h1 class="text-base">Dimensões:</h1>
            <ul class="ml-8 list-disc rounded">
              <li v-for="pdtoInfo in pdto.dimensoes" :key="pdtoInfo">
                {{ pdtoInfo }}
              </li>
            </ul>
          </div>

          <span class="w-full border-b"></span>

          <div class="flex w-full flex-col gap-2 opacity-70">
            <h1 class="text-base">Idiomas:</h1>
            <ul class="ml-8 list-disc rounded">
              <li v-for="pdtoInfo in pdto.idiomas" :key="pdtoInfo">
                {{ pdtoInfo }}
              </li>
            </ul>
          </div>

          <span class="w-full border-b"></span>

          <!-- <ul class="rounded">
            <h1>Extras:</h1>
            <li v-for="pdtoInfo in pdto.extras" :key="pdtoInfo list-disc ml-8">
              {{ pdtoInfo }}
            </li>
          </ul> -->
        </div>
      </div>
      <div class="flex w-full flex-col gap-4 lg:w-1/3">
        <div class="flex w-full flex-col gap-4">
          <p class="text-3xl opacity-80">{{ pdto.titulo }}</p>
          <div class="opacity-75">
            <p>Modelo:</p>
            <p>Disponibilidade:</p>
          </div>
          <p class="font-sans text-2xl opacity-80">R$ {{ pdto.preco }},00</p>
        </div>

        <div class="flex w-full flex-col gap-2">
          <p class="text-sm font-extralight">Qtd</p>
          <input
            class="w-full rounded border px-4 py-1"
            type="number"
            value="1"
            min="1"
          />
        </div>

        <button class="w-full rounded bg-price-color p-3 text-xl text-white">
          Comprar
        </button>

        <div
          class="flex w-full flex-col items-center gap-8 rounded border pb-4"
        >
          <p class="w-full rounded-t bg-price-color px-4 py-2 text-white">
            Formas de pagamento
          </p>
          <img
            src="../assets/Utility-imgs/mercado-pago-logo.png"
            alt="Formas de pagamento"
            class="w-1/2"
          />
          <img
            src="../assets/Utility-imgs/Logonovo_pagseguro-cinza.png"
            alt="Formas de pagamento"
            class="w-1/2"
          />
        </div>

        <div
          class="flex w-full flex-col items-center gap-8 rounded border pb-4"
        >
          <p class="w-full rounded-t bg-price-color px-4 py-2 text-white">
            Formas de Envio
          </p>
          <img
            src="../assets/Utility-imgs/logo-correios-512.png"
            alt="Formas de envio"
            class="w-1/2"
          />
        </div>

        <p class="opacity-70">Etiquetas:</p>
      </div>
    </section>
  </article>
  <section
    class="mx-auto my-8 mb-12 grid w-11/12 grid-cols-2 place-items-center gap-y-4 text-center lg:grid-cols-4"
  >
    <div class="col-span-1 flex flex-col items-center justify-center gap-2">
      <p class="text-lg font-thin text-price-color">Compre com</p>
      <img src="../assets/imgAnuncios/encimaRodape/compra_segura.png" />
      <p class="text-xl font-normal text-price-color">Segurança Certificada</p>
    </div>
    <div class="col-span-1 flex flex-col items-center justify-center gap-2">
      <p class="text-lg font-thin text-price-color">Conte com</p>
      <img src="../assets/imgAnuncios/encimaRodape/entrega.png" />
      <p class="text-xl font-normal text-price-color">
        Entrega Ágil e Garantida
      </p>
    </div>
    <div class="col-span-1 flex flex-col items-center justify-center gap-2">
      <p class="text-lg font-thin text-price-color">Várias</p>
      <img src="../assets/imgAnuncios/encimaRodape/pagamento.png" />
      <p class="text-xl font-normal text-price-color">Formas de Pagamento</p>
    </div>
    <div class="col-span-1 flex flex-col items-center justify-center gap-2">
      <p class="text-lg font-thin text-price-color">Desconto para</p>
      <img src="../assets/imgAnuncios/encimaRodape/desconto.png" />
      <p class="text-xl font-normal text-price-color">Pagamento a Vista</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "produtosView",
  components: {},
  data() {
    return {
      item: {},
      garantia: "",
    };
  },

  beforeCreate() {
    for (let i = 0; i < this.$store.state.produtos.length; i++) {
      if (this.$store.state.produtos[i].id == this.$route.params.id) {
        this.item = this.$store.state.produtos[i];
        break;
      }
    }
  },

  unmounted(){
    this.$store.state.resultadoSearch = []
  }
};
</script>
