<template>
  <article class="w-full">
    <div class="mx-auto flex w-11/12 flex-col items-center gap-4 lg:container">
      <img
        class="w-full rounded border-4 border-white shadow-md"
        src="../assets/Home/bannerHomeScanner.jpg"
      />

      <h3 class="ml-6 self-start font-sans text-2xl">Destaques</h3>

      <div
        class="grid h-full w-full grid-cols-1 place-content-center place-items-center gap-8 md:grid-cols-2 lg:grid-cols-4"
      >
        <section
          class="col-span-1 row-span-1 flex h-full items-center justify-center text-center"
          v-for="produtos in $store.state.produtos.slice(0,16)"
          :key="produtos.id"
        >
          <router-link
            :to="'/produtos/' + produtos.id"
            class="border-btn-border-line flex h-full w-11/12 flex-col items-center justify-between border"
          >
            <img class="w-full" :src="produtos.imagens" alt="img" />

            <div class="my-4 mb-8 flex w-full flex-col gap-4 px-4">
              <p
                class="text-title-color w-full font-sans text-lg font-medium leading-tight"
              >
                {{ produtos.titulo }}
              </p>
              <p class="text-base leading-tight opacity-70">
                {{ produtos.descricao }}
              </p>
              <p class="text-price-color text-lg font-bold">
                R$ {{ produtos.preco }},00
              </p>
            </div>

            <div class="flex h-auto w-full items-center justify-evenly">
              <button
                class="bg-card-btn border-btn-border-line flex w-1/3 items-center justify-center border-t py-4"
              >
                <img
                  class="w-4"
                  src="../assets/Utility-icons/cart-large-minimalistic-svgrepo-com.svg"
                  alt="card-icon"
                />
              </button>
              <button
                class="bg-card-btn border-btn-border-line flex w-1/3 items-center justify-center border-x border-t py-4"
              >
                <img
                  class="w-4"
                  src="../assets/Utility-icons/heart-svgrepo-com.svg"
                  alt="card-icon"
                />
              </button>
              <button
                class="bg-card-btn border-btn-border-line flex w-1/3 items-center justify-center border-t py-4"
              >
                <img
                  class="w-4"
                  src="../assets/Utility-icons/arrows-horizontal-svgrepo-com.svg"
                  alt="card-icon"
                />
              </button>
            </div>
          </router-link>
        </section>
      </div>

      <section
        class="mx-auto my-8 mb-12 grid w-11/12 grid-cols-2 place-items-center gap-y-4 text-center lg:grid-cols-4"
      >
        <div class="col-span-1 flex flex-col items-center justify-center gap-2">
          <p class="text-price-color text-lg font-thin">Compre com</p>
          <img src="../assets/imgAnuncios/encimaRodape/compra_segura.png" />
          <p class="text-price-color text-xl font-normal">
            Segurança Certificada
          </p>
        </div>
        <div class="col-span-1 flex flex-col items-center justify-center gap-2">
          <p class="text-price-color text-lg font-thin">Conte com</p>
          <img src="../assets/imgAnuncios/encimaRodape/entrega.png" />
          <p class="text-price-color text-xl font-normal">
            Entrega Ágil e Garantida
          </p>
        </div>
        <div class="col-span-1 flex flex-col items-center justify-center gap-2">
          <p class="text-price-color text-lg font-thin">Várias</p>
          <img src="../assets/imgAnuncios/encimaRodape/pagamento.png" />
          <p class="text-price-color text-xl font-normal">
            Formas de Pagamento
          </p>
        </div>
        <div class="col-span-1 flex flex-col items-center justify-center gap-2">
          <p class="text-price-color text-lg font-thin">Desconto para</p>
          <img src="../assets/imgAnuncios/encimaRodape/desconto.png" />
          <p class="text-price-color text-xl font-normal">Pagamento a Vista</p>
        </div>
      </section>
    </div>
  </article>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {};
  },
};
</script>

<style></style>
